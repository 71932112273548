<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Smart IMS
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome Back! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <div
            class="auth-login-form mt-2"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email|min:3"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'page-forgot-password'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required|min:6|max:35"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>
            <div>
              <small class="text-danger">{{ errormsg }}</small>
            </div>
            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="login"
            >
              Sign in
            </b-button>
          </div>

        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link href="mailto:sales@smartechc.com">
            <span>Request for an account</span>
          </b-link>
        </b-card-text>
        <footer
          class="footer footer-static text-center"
          style="width:100%"
        >
          <a href="mailto:support@smartechc.com">Help</a>&nbsp;&bull;&nbsp;
          <a
            href="https://www.smartechc.com/privacy"
            target="_blank"
          >Pravicy</a>&nbsp;&bull;&nbsp;
          <a
            href="https://www.smartechc.com/terms"
            target="_blank"
          >T &amp; C</a>&nbsp;&bull;&nbsp;
          <a
            href="https://www.smartechc.com/security"
            target="_blank"
          >Security</a>
        </footer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import SecureLS from 'secure-ls'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

const ls = new SecureLS({ isCompression: false, encodingType: 'des' })

export default {
  components: {
    // BSV
    BButton,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      errormsg: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.LoadingEnd()
  },
  created() {
    ls.remove('vuex')
    ls.remove('userInfo')
    ls.remove('roleInfo')
    ls.remove('claimInfo')
    if (Cookies.get('user')) {
      let tempuser = window.atob(Cookies.get('user'))
      const storedemail = tempuser.split('/')[0]
      const storedpassword = tempuser.split('/')[1]
      this.LoadingStart()
      this.$store.dispatch('user/login', { storedemail, storedpassword })
        .then(() => {
          this.$store.dispatch('user/RefreshUser')
            .then(() => {
              // if remember me
              if (this.status) {
                tempuser = window.btoa(`${this.userEmail}/${this.password}`)
                Cookies.set('user', tempuser, { expires: 14 })
              }
              // this.UpdateAbility()
              this.$ability.update(this.$store.state.user.AppActiveUser.Module)
              this.LoadingEnd()
              this.$router.push('/dashboard')
            })
            .catch(() => {
              this.LoadingEnd()
              // console.info(error.message)
            })
        })
        .catch(error => {
          this.LoadingEnd()
          this.errormsg = error.response.data.errorMessage// 'The username or password is incorrect. Please try again.'
          this.userEmail = ''
          this.password = ''
          this.status = false
        })
    }
  },
  methods: {
    login() {
      const storedemail = this.userEmail
      const storedpassword = this.password
      this.LoadingStart()
      this.$store.dispatch('user/login', { storedemail, storedpassword })
        .then(() => {
          this.$store.dispatch('user/RefreshUser')
            .then(() => {
              // if remember me
              if (this.status) {
                const tempuser = window.btoa(`${this.userEmail}/${this.password}`)
                Cookies.set('user', tempuser, { expires: 14 })
              }
              this.$ability.update(this.$store.state.user.AppActiveUser.Module)
              this.LoadingEnd()
              this.$router.push('/dashboard')
            })
            .catch(() => {
              this.LoadingEnd()
              // console.info(error.message)
            })
        })
        .catch(error => {
          this.LoadingEnd()
          this.errormsg = error.response.data.errorMessage // 'The username or password is incorrect. Please try again.'
          this.userEmail = ''
          this.password = ''
          this.status = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
